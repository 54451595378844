import * as types from './mutation-types'
import { SEARCH_TYPE_FRANCHISEE } from './constants';

// state
export const state = () => ({
  type: SEARCH_TYPE_FRANCHISEE
})

// getters
export const getters = {
  show: state => state.type
}

// mutations
export const mutations = {
  [types.SET_SEARCH_TYPE] (state, type) {
    state.type = type;
  },
}

// actions
export const actions = {
  setType ({ commit }, type ) {
    commit(types.SET_SEARCH_TYPE, type);
  },
}
