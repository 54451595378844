import * as types from './mutation-types'

// state
export const state = () => ({
  show: true
})

// getters
export const getters = {
  show: state => state.show
}

// mutations
export const mutations = {
  [types.SET_SHOW_FOOTER] (state, show) {
    state.show = show
  }
}

// actions
export const actions = {
  showSelf ({ commit }) {
    commit(types.SET_SHOW_FOOTER, true)
  },
  hideSelf ({ commit }) {
    commit(types.SET_SHOW_FOOTER, false)
  }
}
