import * as types from './mutation-types'

// state
export const state = () => ({
  utmCampaign: null,
  utmTerm: null,
})

// getters
export const getters = {
  utmCampaign: state => state.utmCampaign,
  utmTerm: state => state.utmTerm,
}

// mutations
export const mutations = {
  [types.SET_HUBSPOT_FORM_FIELD](state, field) {
    for (const [fieldKey, fieldValue] of Object.entries(field)) {
      state[fieldKey] = fieldValue;
    }
  },
}

// actions
export const actions = {
  setHubspotFormField({ commit, state }, payload) {
    commit(types.SET_HUBSPOT_FORM_FIELD, payload);
  },
}
