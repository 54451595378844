const middleware = {}

middleware['accountFranchisee'] = require('..\\middleware\\accountFranchisee.js')
middleware['accountFranchisee'] = middleware['accountFranchisee'].default || middleware['accountFranchisee']

middleware['accountFranchisor'] = require('..\\middleware\\accountFranchisor.js')
middleware['accountFranchisor'] = middleware['accountFranchisor'].default || middleware['accountFranchisor']

middleware['checkAuth'] = require('..\\middleware\\checkAuth.js')
middleware['checkAuth'] = middleware['checkAuth'].default || middleware['checkAuth']

middleware['cordova'] = require('..\\middleware\\cordova.js')
middleware['cordova'] = middleware['cordova'].default || middleware['cordova']

middleware['stepRegister'] = require('..\\middleware\\stepRegister.js')
middleware['stepRegister'] = middleware['stepRegister'].default || middleware['stepRegister']

export default middleware
