export const mapFranchise = (item) => {
	return {
		id: item.franchise_id,
		versionId: item.version_id,
		businessName: item.business_name,
		slug: item.slug,
		itemUrl: `/franchise-directory/industries/${item.slug_industry}/${item.slug}`,
		itemCompanyUrl: `/company-pages/${item.slug}`,
		imageUrl: item.logo.large ?? '',
		flowStatus: item.flow_status,
		shortDesc: item.short_descr,
		companyDescription: item.company_description,
		companyShortDescription: item.company_short_description,
		like: item.like,
		inComparison: item.in_comparison,
		certifiedByFa: item.certified_by_fa,
		membership: item.membership === 'Abusiness' ? '' : item.membership,
		industry: item.industry,
		investmentMin: item.investment.low ?? 0,
		investmentMax: item.investment.high ?? 0,
		established: item.established,
		franchisingSince: item.franchising_since,
		numberOfLocations: item.number_of_locations,
		location: item.location,
		franchiseFeeMin: item.franchise_fee.low ?? 0,
		franchiseFeeMax: item.franchise_fee.high ?? 0,
		grossProfit: item.gross_profit,
		averageRevenue: item.average_revenue,
		minimumLiquidAssets: item.minimum_liquid_assets,
		totalNetWorth: item.total_net_worth,
		minimumCreditScore: item.minimum_credit_score,
		mainRoyaltyFee: item.main_royalty_fee,
		mainRoyaltyFeePercent: item.main_royalty_fee_percent,
		logo: item.logo,
		rating: item.external_ratings,
		files: item.files,
		tags: item.tags,
    isUserSelect: item.is_select ?? 0,
    isUserSkip: item.is_skip ?? 0,
	}
}

export const sortPreferences = async (data) =>{
  const industry = []
  const states = []
  let invest = null
  data.result.forEach(element => {
    if (element.attribute_name_alias === 'industry')
      industry.push( element.attribute_value_string)

    if (element.attribute_name_alias === 'state_id')
      states.push(Number(element.attribute_value_string))

    if (element.attribute_name_alias === 'total_amount_invest')
      invest = Number(element.attribute_value_string)
  })

  return {industry,states,invest}
}
