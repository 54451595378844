import * as types from './../mutation-types'

// state
export const state = () => ({
  data: [],
  filter: {
    industry: '',
    investment: {
      min: null,
      max: null
    },
    allowed_states_id: '',
  },
  pagination: {
    current_page: 1,
    first: '',
    from: 1,
    last: '',
    last_page: 1,
    next: '',
    path: '',
    per_page: 20,
    prev: null,
    to: 0,
    total: 0
  },
  sort: {},
  itemsState: false,
  busy: false,
})

// getters
export const getters = {
  filter: state => state.filter,
  no_autofilter_fields: state => state.no_autofilter_fields,
  data: state => state.data,
  pagination: state => state.pagination,
  itemsState: state => state.itemsState,
  liked: state => state.liked,
  comparisons: state => state.comparisons,
  busy: state => state.busy,
}

// mutations
export const mutations = {
  [types.FETCH_BUSINESS_SUCCESS] (state, data) {
    state.data = data
  },
  [types.FETCH_BUSINESS_FAILURE] (state) {
    state.data = []
  },
  [types.SET_MAIN_FILTER_PAGINATION](state, pagination) {
    state.pagination = Object.assign(state.pagination, pagination)
  },
  [types.SET_ITEMS_STATE](state, itemsState) {
    state.itemsState = itemsState
  },
  [types.SET_SORTING](state, sort) {
    state.sort = Object.assign(state.sort, sort)
  },
  [types.SET_BUSY_STATUS](state, status) {
    state.busy = status
  },
  [types.SET_FILTER_ITEM](state, {key, value}) {
    if (typeof value === 'object' && value !== null) {
      Object.assign(state.filter[key], value)
    } else {
      state.filter[key] = value
    }
  }
}

// actions
export const actions = {
  async fetchBusiness ({ commit, state }) {
    // @TODO change form data to json for ssr
    let formData = new FormData();
    commit(types.SET_BUSY_STATUS, true);
    for (let k in state.filter) {
      switch (typeof state.filter[k]) {
        case 'boolean':
          if (state.filter[k] === true) {
            formData.append(k, state.filter[k])
          }
          break
        case 'object':
          if (state.filter[k] !== null && state.filter[k].min) {
            formData.append(`${k}[0]`, state.filter[k].min)
          }
          if (state.filter[k] !== null && state.filter[k].max) {
            formData.append(`${k}[1]`, state.filter[k].max)
          }
          break
        default:
          if (state.filter[k] !== null && state.filter[k] !== '') {
            formData.append(`${k}`, state.filter[k])
          }
          break
      }
    }
    try {
      let sortQuery = state.sort
      if (typeof sortQuery.sortBy === 'undefined' || sortQuery.sortBy === '' ) {
        sortQuery = `sort=-membership,business_name`
      } else {
        sortQuery = `sort=${sortQuery.sortDesc ? '-' : ''}${sortQuery.sortBy}`
      }

      const { data } = await this.$axios.post(`/api/v1/search?page=${state.pagination.current_page}&${sortQuery}&perPage=${state.pagination.per_page}`,
        formData
      )
      const businesses = data.data.map(b => {
        return {
          id: b.franchise_id,
          business_name: b.business_name.attribute_value_string,
          rating: b.rating,
          membership: b.membership.attribute_value_string === 'Abusiness' ? '' : b.membership.attribute_value_string,
          imageUrl: b.logo,
          itemUrl: `/franchise-directory/industries/${b.slug_industry.attribute_value_string}/${b.slug}`,
          itemCompanyUrl: `/company-pages/${b.slug}`,
          fddUrl: b.files.fdd ? b.files.fdd.url : null,
          shortDesc: b.short_descr.attribute_value_string,
          investment: b.investment.attribute_value_numeric_low,
          average_revenue: b.average_revenue.attribute_value_string,
          franchise_fee: b.franchise_fee.attribute_value_numeric_low,
          franchiseNumber: b.number,
          like: b.like,
          in_comparison: b.in_comparison,
          slug: b.slug,
          number_of_locations: Number.isNaN(Number(b.number_of_locations.attribute_value_string))? '':b.number_of_locations.attribute_value_string,
          established: b.established.attribute_value_string,
          flow_status: b.flow_status,
        }
      })
      const pagination = { ...data }

      delete(pagination.data)

      commit(types.FETCH_BUSINESS_SUCCESS, businesses)
      commit(types.SET_MAIN_FILTER_PAGINATION, pagination)
      commit(types.SET_BUSY_STATUS, false);

    } catch (e) {
      console.log(e)
      commit(types.FETCH_BUSINESS_FAILURE)
    }
  },
}
