import * as types from './mutation-types';

// state
export const state = () => ({
  ratingToken: null,
  ratingChannel: null,
  ratingQuestions: [],
  enableRateIt: false,
  relatedFranchiseId: null,
  relatedFranchiseName: null,
});

// getters
export const getters = {
  ratingToken: (state) => state.ratingToken,
  ratingChannel: (state) => state.ratingChannel,
  ratingQuestions: (state) => state.ratingQuestions,
  enableRateIt: (state) => state.enableRateIt,
  relatedFranchiseId: (state) => state.relatedFranchiseId,
  relatedFranchiseName: (state) => state.relatedFranchiseName,
};

// mutations
export const mutations = {
  [types.SET_FRANCHISEE_RATING_TOKEN](state, token) {
    state.ratingToken = token
  },
  [types.SET_FRANCHISEE_RATING_CHANNEL](state, channel) {
    state.ratingChannel = channel
  },
  [types.SET_FRANCHISEE_RATING_QUESTIONS](state, ratingQuestions) {
    state.ratingQuestions = ratingQuestions
  },
  [types.UPDATE_FRANCHISEE_RATING_STATUS](state, status) {
    state.enableRateIt = status
  },
  [types.UPDATE_RELATED_FRANCHISE_ID](state, id) {
    state.relatedFranchiseId = id
  },
  [types.UPDATE_RELATED_FRANCHISE_NAME](state, name) {
    state.relatedFranchiseName = name
  },
};

// actions
export const actions = {
  updateSurveyRatingUserData({commit, dispatch}, {token, channel, questions, franchiseId,franchiseName}) {
    commit(types.SET_FRANCHISEE_RATING_TOKEN, token)
    commit(types.SET_FRANCHISEE_RATING_CHANNEL, channel)
    commit(types.SET_FRANCHISEE_RATING_QUESTIONS, questions)
    commit(types.UPDATE_RELATED_FRANCHISE_ID, franchiseId)
    commit(types.UPDATE_RELATED_FRANCHISE_NAME, franchiseName)
    dispatch('updateStatusRateIt', true)
  },
  updateStatusRateIt({commit}, status) {
    commit(types.UPDATE_FRANCHISEE_RATING_STATUS, status)
  },
  async associateUserWithRating({state, commit}) {

    if(state.ratingToken === null)
      return

    const url = `api/v2/business-survey-rating/${state.ratingToken}/set-user`
    try {
      await this.$axios.get(url);
      commit(types.SET_FRANCHISEE_RATING_TOKEN, null)
      commit(types.SET_FRANCHISEE_RATING_CHANNEL, null)
      commit(types.SET_FRANCHISEE_RATING_QUESTIONS, [])
      commit(types.UPDATE_RELATED_FRANCHISE_ID, null)
      commit(types.UPDATE_RELATED_FRANCHISE_NAME, null)
    } catch (e) {}
  },
};
