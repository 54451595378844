import {cloneDeep, difference} from 'lodash/fp'
import {mapFranchise, sortPreferences} from "~/store/helper"
import {USER_PREFERENCES_TOTAL_AMOUNT_INVEST} from "~/store/constants"
import * as types from '../../mutation-types'

// state
export const state = () => ({
  franchises: [],
  selectedFranchises: [],
  pagination: {
    current_page: 1,
    first: '',
    from: 1,
    last: '',
    last_page: 33,
    next: '',
    path: '',
    per_page: 7,
    prev: null,
    to: 0,
    total: 0
  },
  selectedPagination: {
    current_page: 1,
    first: '',
    from: 1,
    last: '',
    last_page: 33,
    next: '',
    path: '',
    per_page: 12,
    prev: null,
    to: 0,
    total: 0
  },
  currentPreferenceIndex: 0,
  preferencesIndustries: [],
  preferencesStates: [],
  preferencesInvestment: null,
  savedPreferences: {
    industry: [],
    states: [],
    investment: null
  },
  busy: false,
  notSavedPreferences: false,
  showHelper: false,
  delayedActionsUpdate: {
    selected: [],
    skip: [],
  }
})

// getters
export const getters = {
  franchises: state => state.franchises,
  selectedFranchises: state => state.selectedFranchises,
  pagination: state => state.pagination,
  selectedPagination: state => state.selectedPagination,
  currentPreferenceIndex: state => state.currentPreferenceIndex,
  preferencesIndustries: state => state.preferencesIndustries,
  preferencesStates: state => state.preferencesStates,
  preferencesInvestment: state => state.preferencesInvestment,
  savedPreferences: state => state.savedPreferences,
  busy: state => state.busy,
  showHelper: state => state.showHelper,
  delayedActionsUpdate: state => state.delayedActionsUpdate,
}

export const mutations = {
  [types.FETCH_MY_MATCHES_SUCCESS](state, franchise) {
    state.franchises = franchise
  },
  [types.FETCH_MY_MATCHES_FAILURE](state) {
    state.franchises = []
  },
  [types.UPDATE_PAGINATION](state, pagination) {
    state.pagination = pagination
  },
  [types.CHANGE_PAGE](state, page) {
    state.pagination.current_page = page
  },
  [types.FETCH_SELECTED_SUCCESS](state, franchise) {
    state.selectedFranchises = franchise
  },
  [types.FETCH_SELECTED_FAILURE](state) {
    state.selectedFranchises = []
  },
  [types.UPDATE_SELECTED_PAGINATION](state, pagination) {
    state.selectedPagination = pagination
  },
  [types.CHANGE_SELECTED_PAGE](state, page) {
    state.selectedPagination.current_page = page
  },
  [types.CHANGE_MOBILE_PREFERENCES_STEP](state, screenIndex) {
    state.currentPreferenceIndex = screenIndex
  },
  [types.UPDATE_MOBILE_PREFERENCES_INDUSTRY](state, preferencesIndustries) {
    state.preferencesIndustries = preferencesIndustries
  },
  [types.UPDATE_MOBILE_PREFERENCES_STATES](state, preferencesStates) {
    state.preferencesStates = preferencesStates
  },
  [types.UPDATE_MOBILE_PREFERENCES_INVEST](state, preferencesInvestment) {
    state.preferencesInvestment = preferencesInvestment
  },
  [types.UPDATE_MOBILE_PREFERENCES_SAVED](state, {industry, states, investment}) {
    state.savedPreferences = {
      industry,
      states,
      investment
    }
  },
  [types.UPDATE_STATUS_FETCH](state, status) {
    state.busy = status
  },
  [types.CHANGE_HELPER_VISIBILITY](state, visibility) {
    state.showHelper = visibility
  },
  [types.ADDED_DELAYED_ACTIONS_SELECT](state, franchiseId) {
    if (franchiseId === '')
      return
    state.delayedActionsUpdate.selected.push(franchiseId)
  },
  [types.ADDED_DELAYED_ACTIONS_SKIP](state, franchiseId) {
    if (franchiseId === '')
      return
    state.delayedActionsUpdate.skip.push(franchiseId)
  },
  [types.CLEAR_DELAYED_ACTIONS](state) {
    state.delayedActionsUpdate.selected = []
    state.delayedActionsUpdate.skip = []
  },
}

export const actions = {
  changePreferenceScreen({commit}, screenIndex) {
    commit(types.CHANGE_MOBILE_PREFERENCES_STEP, screenIndex)
  },
  nextPage({commit, state}) {
    const currentPage = state.pagination.current_page
    commit(types.CHANGE_PAGE, currentPage + 1)
  },
  firstPage({commit}) {
    commit(types.CHANGE_PAGE, 1)
  },
  nextSelectedPage({commit, state}) {
    const currentPage = state.selectedPagination.current_page

    commit(types.CHANGE_SELECTED_PAGE, currentPage + 1)
  },
  firstSelectedPage({commit}) {
    commit(types.CHANGE_SELECTED_PAGE, 1)
  },
  async fetchFranchises({commit, state, rootState}) {
    commit(types.UPDATE_STATUS_FETCH, true)
    try {
      const params = {
        page: state.pagination.current_page,
        per_page: state.pagination.per_page,
      }

      if (state.preferencesStates.length)
        params.states_id = state.preferencesStates

      if (state.preferencesIndustries.length)
        params.industry = state.preferencesIndustries

      if (state.preferencesInvestment) {
        const currentInvestment = USER_PREFERENCES_TOTAL_AMOUNT_INVEST.find(item => item.value === state.preferencesInvestment)
        if (currentInvestment.min !== null && currentInvestment.max !== null)
          params.investment = [currentInvestment.min, currentInvestment.max]
      }
      const url = rootState.mobile.franchisee.signUp.flowPreviewStarted
        ? `/api/v2/businesses/?sort[business_name]=asc`
        : `/api/v2/businesses/carousel?sort[business_name]=asc`

      const {data} = await this.$axios.get(url, {params})

      const franchisesList = cloneDeep(data.result.data).map(item => mapFranchise(item))
      delete (data.result.data)

      commit(types.FETCH_MY_MATCHES_SUCCESS, franchisesList)
      commit(types.UPDATE_PAGINATION, data.result)
    } catch (e) {
      commit(types.FETCH_MY_MATCHES_FAILURE)
    }
    commit(types.UPDATE_STATUS_FETCH, false)
  },
  async fetchSelectedFranchises({commit, state}, payload) {
    commit(types.UPDATE_STATUS_FETCH, true)
    try {
      const params = {
        page: state.selectedPagination.current_page,
        per_page: state.selectedPagination.per_page,
      }

      const {data} = await this.$axios.get(
        `/api/v2/businesses/action/select/${payload.userId}`, {params}
      )

      const franchisesList = cloneDeep(data.result.data).map(item => mapFranchise(item))
      delete (data.result.data)

      commit(types.FETCH_SELECTED_SUCCESS, franchisesList)
      commit(types.UPDATE_SELECTED_PAGINATION, data.result)
    } catch (e) {
      commit(types.FETCH_SELECTED_FAILURE)
    }
    commit(types.UPDATE_STATUS_FETCH, false)
  },
  async fetchPreferences({commit, state}) {
    commit(types.UPDATE_STATUS_FETCH, true)
    if (state.preferencesIndustries.length > 0)
      return
    if (state.preferencesStates.length > 0)
      return

    if (state.preferencesInvestment !== null && state.preferencesInvestment !== '')
      return

    try {
      const {data} = await this.$axios.get(`/api/v1/user/preferences`)
      const preferences = await sortPreferences(data)
      commit(types.UPDATE_MOBILE_PREFERENCES_INDUSTRY, preferences.industry)
      commit(types.UPDATE_MOBILE_PREFERENCES_STATES, preferences.states)
      commit(types.UPDATE_MOBILE_PREFERENCES_INVEST, preferences.invest)
      commit(types.UPDATE_MOBILE_PREFERENCES_SAVED, {
        industry: preferences.industry,
        states: preferences.states,
        investment: preferences.invest
      })
    } catch (e) {
      console.log(e)
    }

    commit(types.UPDATE_STATUS_FETCH, false)
  },
  updateIndustry({state, commit}, data) {
    const selectedIndustries = cloneDeep(state.preferencesIndustries)
    const findIndex = selectedIndustries.findIndex(item => item === data.value)

    if (findIndex !== -1)
      selectedIndustries.splice(findIndex, 1)

    if (findIndex === -1)
      selectedIndustries.push(data.value)

    commit(types.UPDATE_MOBILE_PREFERENCES_INDUSTRY, selectedIndustries)
  },
  updateStates({state, commit}, data) {
    const selectedStates = cloneDeep(state.preferencesStates)
    const findIndex = selectedStates.findIndex(item => item === data.id)

    if (findIndex !== -1)
      selectedStates.splice(findIndex, 1)

    if (findIndex === -1)
      selectedStates.push(data.id)

    commit(types.UPDATE_MOBILE_PREFERENCES_STATES, selectedStates)
  },
  clearAllStates({commit}) {
    commit(types.UPDATE_MOBILE_PREFERENCES_STATES, [])
  },
  addAllStates({commit}, data) {
    commit(types.UPDATE_MOBILE_PREFERENCES_STATES, data.map(item => item.id))
  },
  updateInvestment({state, commit}, data) {
    if (state.preferencesInvestment === data)
      commit(types.UPDATE_MOBILE_PREFERENCES_INVEST, null)

    if (state.preferencesInvestment !== data)
      commit(types.UPDATE_MOBILE_PREFERENCES_INVEST, data.value)
  },
  showHelper({commit}) {
    commit(types.CHANGE_HELPER_VISIBILITY, true)
  },
  hideHelper({commit}) {
    commit(types.CHANGE_HELPER_VISIBILITY, false)
  },
  clearPreferences({commit}) {
    commit(types.UPDATE_MOBILE_PREFERENCES_INDUSTRY, [])
    commit(types.UPDATE_MOBILE_PREFERENCES_STATES, [])
    commit(types.UPDATE_MOBILE_PREFERENCES_INVEST, null)
  },
  async savePreferences({state}) {
    try {
      await this.$axios.put('/api/v1/user/preferences',
        {
          industries: state.preferencesIndustries,
          state_ids: state.preferencesStates,
          total_amount_invest: state.preferencesInvestment >= 0 ? state.preferencesInvestment : null
        }
      )
    } catch (e) {

    }
  },
  addedToDelayedActions({commit}, {selectId, skipId}) {
    commit(types.ADDED_DELAYED_ACTIONS_SELECT, selectId)
    commit(types.ADDED_DELAYED_ACTIONS_SKIP, skipId)
  },
  clearedDelayedActions({commit}) {
    commit(types.CLEAR_DELAYED_ACTIONS)
  }
}

