import * as types from './../../mutation-types'

export const state = () => ({
  historyList: {},
  history: {},
  route: `/api/v2/franchisor-contacts/history/`,
  busy: true,
})

export const getters = {
  historyListPaginated: state => state.historyList,
  historyList: state => state.historyList.data,
  history: state => state.history,
  busy: state => state.busy,
}

export const mutations = {
  [types.FETCH_HISTORY_LIST_SUCCESS] (state, data) {
    state.historyList = data
  },
  [types.FETCH_HISTORY_LIST_FAILURE] (state) {
    state.historyList = {}
  },
  [types.SET_HISTORY_BUSY] (state, status) {
    state.busy = status
  },
}

export const actions = {
  async fetchHistoryList({ commit, rootState }, params) {
    commit(types.SET_HISTORY_BUSY, true)
    try {
      const paramsUpdated = params
      const franchisorId = rootState['account-franchisor'].ownerData?.id ?? null
      const franchiseId = rootState['account-franchisor'].franchiseId ?? null

      if (franchisorId) paramsUpdated.franchisor_id = franchisorId
      if (franchiseId) paramsUpdated.franchise_id = franchiseId

      const { data } = await this.$axios.get(`/api/v2/franchisor-contacts/history/`, { params: paramsUpdated })
      commit(types.FETCH_HISTORY_LIST_SUCCESS, data.result)
    } catch (e) {
      commit(types.FETCH_HISTORY_LIST_FAILURE)
    }
    commit(types.SET_HISTORY_BUSY, false)
  },
}
