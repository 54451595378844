import * as types from './mutation-types'

// state
export const state = () => ({
  show: false,
  src: '',
  tag: '',
})

// getters
export const getters = {
  show: state => state.show,
  src: state => state.src
}

// mutations
export const mutations = {
  [types.SET_SHOW_IFRAME_MODAL] (state, show) {
    state.show = show
  },
  [types.SET_SRC_IFRAME_MODAL] (state, src) {
    state.src = src
  },
  [types.SET_SRC_IFRAME_MODAL_TAG] (state, tag) {
    state.tag = tag
  },
}

// actions
export const actions = {
  setSrc ({ commit }, src) {
    commit(types.SET_SRC_IFRAME_MODAL, src)
  },
  setShow ({ commit }, show) {
    commit(types.SET_SHOW_REGISTRATION_MODAL, show)
  },
  setTag ({commit}, tag) {
    commit(types.SET_SRC_IFRAME_MODAL_TAG, tag)
  },
  openModal ({ commit }, src) {
    commit(types.SET_SHOW_IFRAME_MODAL, true)
    commit(types.SET_SRC_IFRAME_MODAL, src)
  },
  closeModal ({ commit }) {
    commit(types.SET_SHOW_IFRAME_MODAL, false)
    commit(types.SET_SRC_IFRAME_MODAL_TAG, '')
  }
}
