import Vue from 'vue';

import {
  ModalPlugin,
  BContainer,
  BRow,
  BCol,
  BDropdown,
  BModal,
  BAlert,
  BTooltip,
  BCollapse,
  BPagination,
  BProgressBar,
  BProgress,
  BTable,
  VBTooltip,
  VBToggle
} from 'bootstrap-vue';

Vue.use(ModalPlugin);

Vue.component('BContainer', BContainer);
Vue.component('BRow', BRow);
Vue.component('BCol', BCol);
Vue.component('BDropdown', BDropdown);
Vue.component('BModal', BModal);
Vue.component('BAlert', BAlert);
Vue.component('BTooltip', BTooltip);
Vue.component('BCollapse', BCollapse);
Vue.component('BPagination', BPagination);
Vue.component('BProgressBar', BProgressBar);
Vue.component('BProgress', BProgress);
Vue.component('BTable', BTable);

Vue.directive('BTooltip', VBTooltip);
Vue.directive('BToggle', VBToggle);
