/* eslint-disable no-param-reassign */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import * as types from '~/store/mutation-types';

// state
export const state = () => ({
  visible: false,
  isFooterContactForm: false,
});

// getters
export const getters = {
  visible: (state) => state.visible,
  isFooterContactForm: (state) => state.isFooterContactForm,
};

// mutations
export const mutations = {
  [types.SET_VISIBILITY_CONTACT_MODAL](state, visible) {
    state.visible = visible;
  },
  [types.SET_IS_FOOTER_CONTACT_FORM](state, isFooterContactForm) {
    state.isFooterContactForm = isFooterContactForm;
  },
};

// actions
export const actions = {
  /**
   * Show contact form
   * @param commit
   * @param isFooterContactForm
   */
  show({ commit }, isFooterContactForm = false) {
    commit(types.SET_IS_FOOTER_CONTACT_FORM, isFooterContactForm);
    commit(types.SET_VISIBILITY_CONTACT_MODAL, true);
  },
  /**
   * Hide contact form
   * @param commit
   */
  hide({ commit }) {
    commit(types.SET_VISIBILITY_CONTACT_MODAL, false);
  },
};
