import cloneDeep from 'lodash/cloneDeep'
import * as types from './mutation-types'

// state
export const state = () => ({
  topListArr: [],
  topListNotPinnedArr: [],
  currentTopList: {},
  currentSlug: '',
  franchiseListArr: [],
  pagination: {
    current_page: 1,
    total: 0
  },
  busy: false,
  busyFetchFranchise: false,
})

// getters
export const getters = {
  topListArr: state => state.topListArr,
  topListNotPinnedArr: state => state.topListNotPinnedArr,
  currentSlug: state => state.currentSlug,
  currentTopList: state => state.currentTopList,
  franchiseListArr: state => state.franchiseListArr,
  pagination: state => state.pagination,
  busy: state => state.busy,
  busyFetchFranchise: state => state.busyFetchFranchise,
}

// mutations
export const mutations = {
  [types.UPDATE_TOP_LIST_ARR](state, topListArr) {
    state.topListArr = topListArr
  },
  [types.UPDATE_TOP_LIST_NOT_PINNED_ARR](state, topListArr) {
    state.topListNotPinnedArr = topListArr
  },
  [types.UPDATE_TOP_LIST_CURRENT_SLUG](state, currentSlug) {
    state.currentSlug = currentSlug
  },
  [types.UPDATE_TOP_LIST_CURRENT_TOP](state, topList) {
    state.currentTopList = topList
  },
  [types.UPDATE_TOP_FRANCHISE_LIST_ARR](state, franchiseListArr) {
    state.franchiseListArr = franchiseListArr
  },
  [types.UPDATE_TOP_FRANCHISE_LIST_PAGINATION](state, pagination) {
    state.pagination = pagination
  },
  [types.UPDATE_TOP_LIST_STATUS_BUSY](state, status) {
    state.busy = status
  },
  [types.UPDATE_TOP_FRANCHISE_LIST_STATUS_BUSY](state, status) {
    state.busyFetchFranchise = status
  },
}

// actions
export const actions = {
  async fetchTopListArr({commit, state}) {
    if (state.topListArr.length > 0)
      return

    commit(types.UPDATE_TOP_LIST_STATUS_BUSY, true)

    try {
      const url = '/api/v2/page/get-list-top-franchise-pinned'
      const {data} = await this.$axios.get(url)
      commit(types.UPDATE_TOP_LIST_ARR, data.result)
    } catch (e) {
      console.log(e)
    }

    commit(types.UPDATE_TOP_LIST_STATUS_BUSY, false)
  },
  async fetchTopListArrNotPinned({commit, state}) {
    if (state.topListNotPinnedArr.length > 0)
      return

    commit(types.UPDATE_TOP_LIST_STATUS_BUSY, true)

    try {
      const url = '/api/v2/page/get-list-top-franchise-not-pinned'
      const {data} = await this.$axios.get(url)
      commit(types.UPDATE_TOP_LIST_NOT_PINNED_ARR, data.result)
    } catch (e) {
      console.log(e)
    }

    commit(types.UPDATE_TOP_LIST_STATUS_BUSY, false)
  },
  async fetchTopList({commit, state}) {
    if (state.currentSlug === '')
      return

    commit(types.UPDATE_TOP_FRANCHISE_LIST_STATUS_BUSY, true)

    try {
      const url = `api/v2/page/get-top-franchise/${state.currentSlug}`
      const {data} = await this.$axios.get(url)

      const topFranchise = cloneDeep(data.result)
      const franchiseList = cloneDeep(data.result.franchise_list.data)
      const pagination = cloneDeep(data.result.franchise_list)

      commit(types.UPDATE_TOP_LIST_CURRENT_TOP, topFranchise)
      commit(types.UPDATE_TOP_FRANCHISE_LIST_ARR, franchiseList)
      commit(types.UPDATE_TOP_FRANCHISE_LIST_PAGINATION, pagination)
    } catch (e) {
      console.log(e)
    }
    commit(types.UPDATE_TOP_FRANCHISE_LIST_STATUS_BUSY, false)
  },
  updateCurrentSlug({commit}, slug) {
    commit(types.UPDATE_TOP_LIST_CURRENT_SLUG, slug)
  },
}
