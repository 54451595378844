import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

import _4194233e from '..\\pages\\about.vue'
import _1d214601 from '..\\pages\\account.vue'
import _08377b61 from '..\\pages\\account\\index.vue'
import _2d0a124e from '..\\pages\\account\\comparisons.vue'
import _a14d8eba from '..\\pages\\account\\dashboard.vue'
import _efcfc5d4 from '..\\pages\\account\\favorite-franchises.vue'
import _1b2e0c7f from '..\\pages\\account\\franchise-university.vue'
import _0bf81113 from '..\\pages\\account\\my-matches.vue'
import _5c839210 from '..\\pages\\account\\profile.vue'
import _1e28b527 from '..\\pages\\account-franchisor.vue'
import _2f31d57b from '..\\pages\\account-franchisor\\index.vue'
import _63ffe66c from '..\\pages\\account-franchisor\\budget.vue'
import _297bd2f0 from '..\\pages\\account-franchisor\\contacts\\index.vue'
import _81f61486 from '..\\pages\\account-franchisor\\dashboard.vue'
import _5d2503a3 from '..\\pages\\account-franchisor\\onboarding-packet.vue'
import _4c9b8a72 from '..\\pages\\account-franchisor\\submissions\\index.vue'
import _c97f632a from '..\\pages\\account-franchisor\\tasks\\index.vue'
import _0c4f541b from '..\\pages\\account-franchisor\\contacts\\_contact.vue'
import _218e739e from '..\\pages\\account-franchisor\\_franchiseId\\steps\\_step\\index.vue'
import _4eff06c6 from '..\\pages\\account-franchisor\\_franchiseId\\steps\\_step\\chat\\index.vue'
import _4d48657e from '..\\pages\\account-franchisor\\_franchiseId\\steps\\_step\\chat\\_slug.vue'
import _6c4a7078 from '..\\pages\\blog\\index.vue'
import _eea55d90 from '..\\pages\\chat-page.vue'
import _5582dc8c from '..\\pages\\company-pages\\index.vue'
import _1dc1cb14 from '..\\pages\\contact-pre-register.vue'
import _1a6fdd12 from '..\\pages\\disclaimer.vue'
import _23708165 from '..\\pages\\DM.vue'
import _6926da19 from '..\\pages\\editor\\index.vue'
import _00f8fd5e from '..\\pages\\franchise-directory\\index.vue'
import _312fe946 from '..\\pages\\franchise-glossary-definitions.vue'
import _d29847cc from '..\\pages\\franchise-university.vue'
import _259052bc from '..\\pages\\franchisee-register.vue'
import _938cd038 from '..\\pages\\franchisee-steps\\index.vue'
import _3078834c from '..\\pages\\franchising-faqs.vue'
import _b624e1a8 from '..\\pages\\industry-reports.vue'
import _c3f2e39c from '..\\pages\\membership.vue'
import _f23516f8 from '..\\pages\\new-landing\\index.vue'
import _12ab9d04 from '..\\pages\\own-franchise-steps.vue'
import _0aa7ac6f from '..\\pages\\pcmania\\index.vue'
import _953c421a from '..\\pages\\privacy-policy.vue'
import _4d52bfbe from '..\\pages\\privacy-statement.vue'
import _158a1062 from '..\\pages\\radio.vue'
import _b83e54e8 from '..\\pages\\see-franchises.vue'
import _ae28f14a from '..\\pages\\terms.vue'
import _3646f844 from '..\\pages\\test-component.vue'
import _81761148 from '..\\pages\\test-editor.vue'
import _0f643e10 from '..\\pages\\top-franchises\\index.vue'
import _1715fbcf from '..\\pages\\auth\\auto-login.vue'
import _6e4e63e4 from '..\\pages\\auth\\logout.vue'
import _47cabad5 from '..\\pages\\franchise-directory\\industries\\index.vue'
import _2d246594 from '..\\pages\\franchisee-steps\\chat.vue'
import _2bf9f101 from '..\\pages\\new\\editor\\index.vue'
import _3ad8f611 from '..\\pages\\blog\\category\\_category.vue'
import _4e0ae35e from '..\\pages\\blog\\post\\_post.vue'
import _55baa242 from '..\\pages\\franchise-directory\\industries\\_industry\\index.vue'
import _442a6492 from '..\\pages\\new\\landing\\_landing\\index.vue'
import _5366e2c4 from '..\\pages\\franchise-directory\\industries\\_industry\\_company\\index.vue'
import _540400fa from '..\\pages\\franchise-directory\\industries\\_industry\\_slug.vue'
import _46ac1253 from '..\\pages\\new\\landing\\_landing\\_page.vue'
import _51b0417c from '..\\pages\\franchise-directory\\industries\\_industry\\_company\\_slug.vue'
import _4d804788 from '..\\pages\\company-pages\\_company\\index.vue'
import _20b28dc0 from '..\\pages\\new-landing\\_page.vue'
import _01b8f203 from '..\\pages\\top-franchises\\_category.vue'
import _50ed8a18 from '..\\pages\\company-pages\\_company\\_slug.vue'
import _02246db4 from '..\\pages\\index.vue'
import _47238fea from '..\\pages\\index\\auth\\login.vue'
import _88edfa1e from '..\\pages\\index\\auth\\reset.vue'
import _29211ac3 from '..\\pages\\index\\email\\confirm-change.vue'
import _1d0d7a2e from '..\\pages\\index\\auth\\verify\\_userId.vue'
import _d2bbca64 from '..\\pages\\_landing\\index.vue'
import _0139412c from '..\\pages\\_landing\\_page.vue'

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _4194233e,
    name: "about___en"
  }, {
    path: "/account",
    component: _1d214601,
    children: [{
      path: "",
      component: _08377b61,
      name: "account___en"
    }, {
      path: "comparisons",
      component: _2d0a124e,
      name: "account-comparisons___en"
    }, {
      path: "dashboard",
      component: _a14d8eba,
      name: "account-dashboard___en"
    }, {
      path: "favorite-franchises",
      component: _efcfc5d4,
      name: "account-favorite-franchises___en"
    }, {
      path: "franchise-university",
      component: _1b2e0c7f,
      name: "account-franchise-university___en"
    }, {
      path: "my-matches",
      component: _0bf81113,
      name: "account-my-matches___en"
    }, {
      path: "profile",
      component: _5c839210,
      name: "account-profile___en"
    }]
  }, {
    path: "/account-franchisor",
    component: _1e28b527,
    children: [{
      path: "",
      component: _2f31d57b,
      name: "account-franchisor___en"
    }, {
      path: "budget",
      component: _63ffe66c,
      name: "account-franchisor-budget___en"
    }, {
      path: "contacts",
      component: _297bd2f0,
      name: "account-franchisor-contacts___en"
    }, {
      path: "dashboard",
      component: _81f61486,
      name: "account-franchisor-dashboard___en"
    }, {
      path: "onboarding-packet",
      component: _5d2503a3,
      name: "account-franchisor-onboarding-packet___en"
    }, {
      path: "submissions",
      component: _4c9b8a72,
      name: "account-franchisor-submissions___en"
    }, {
      path: "tasks",
      component: _c97f632a,
      name: "account-franchisor-tasks___en"
    }, {
      path: "contacts/:contact",
      component: _0c4f541b,
      name: "account-franchisor-contacts-contact___en"
    }, {
      path: ":franchiseId/steps/:step",
      component: _218e739e,
      name: "account-franchisor-franchiseId-steps-step___en"
    }, {
      path: ":franchiseId/steps/:step?/chat",
      component: _4eff06c6,
      name: "account-franchisor-franchiseId-steps-step-chat___en"
    }, {
      path: ":franchiseId/steps/:step?/chat/:slug",
      component: _4d48657e,
      name: "account-franchisor-franchiseId-steps-step-chat-slug___en"
    }]
  }, {
    path: "/blog",
    component: _6c4a7078,
    name: "blog___en"
  }, {
    path: "/chat-page",
    component: _eea55d90,
    name: "chat-page___en"
  }, {
    path: "/company-pages",
    component: _5582dc8c,
    name: "company-pages___en"
  }, {
    path: "/contact-pre-register",
    component: _1dc1cb14,
    name: "contact-pre-register___en"
  }, {
    path: "/disclaimer",
    component: _1a6fdd12,
    name: "disclaimer___en"
  }, {
    path: "/DM",
    component: _23708165,
    name: "DM___en"
  }, {
    path: "/editor",
    component: _6926da19,
    name: "editor___en"
  }, {
    path: "/franchise-directory",
    component: _00f8fd5e,
    name: "franchise-directory___en"
  }, {
    path: "/franchise-glossary-definitions",
    component: _312fe946,
    name: "franchise-glossary-definitions___en"
  }, {
    path: "/franchise-university",
    component: _d29847cc,
    name: "franchise-university___en"
  }, {
    path: "/franchisee-register",
    component: _259052bc,
    name: "franchisee-register___en"
  }, {
    path: "/franchisee-steps",
    component: _938cd038,
    name: "franchisee-steps___en"
  }, {
    path: "/franchising-faqs",
    component: _3078834c,
    name: "franchising-faqs___en"
  }, {
    path: "/industry-reports",
    component: _b624e1a8,
    name: "industry-reports___en"
  }, {
    path: "/membership",
    component: _c3f2e39c,
    name: "membership___en"
  }, {
    path: "/new-landing",
    component: _f23516f8,
    name: "new-landing___en"
  }, {
    path: "/own-franchise-steps",
    component: _12ab9d04,
    name: "own-franchise-steps___en"
  }, {
    path: "/pcmania",
    component: _0aa7ac6f,
    name: "pcmania___en"
  }, {
    path: "/privacy-policy",
    component: _953c421a,
    name: "privacy-policy___en"
  }, {
    path: "/privacy-statement",
    component: _4d52bfbe,
    name: "privacy-statement___en"
  }, {
    path: "/radio",
    component: _158a1062,
    name: "radio___en"
  }, {
    path: "/see-franchises",
    component: _b83e54e8,
    name: "see-franchises___en"
  }, {
    path: "/terms",
    component: _ae28f14a,
    name: "terms___en"
  }, {
    path: "/test-component",
    component: _3646f844,
    name: "test-component___en"
  }, {
    path: "/test-editor",
    component: _81761148,
    name: "test-editor___en"
  }, {
    path: "/top-franchises",
    component: _0f643e10,
    name: "top-franchises___en"
  }, {
    path: "/auth/auto-login",
    component: _1715fbcf,
    name: "auth-auto-login___en"
  }, {
    path: "/auth/logout",
    component: _6e4e63e4,
    name: "auth-logout___en"
  }, {
    path: "/franchise-directory/industries",
    component: _47cabad5,
    name: "franchise-directory-industries___en"
  }, {
    path: "/franchisee-steps/chat",
    component: _2d246594,
    name: "franchisee-steps-chat___en"
  }, {
    path: "/new/editor",
    component: _2bf9f101,
    name: "new-editor___en"
  }, {
    path: "/blog/category/:category?",
    component: _3ad8f611,
    name: "blog-category-category___en"
  }, {
    path: "/blog/post/:post?",
    component: _4e0ae35e,
    name: "blog-post-post___en"
  }, {
    path: "/franchise-directory/industries/:industry",
    component: _55baa242,
    name: "franchise-directory-industries-industry___en"
  }, {
    path: "/new/landing/:landing",
    component: _442a6492,
    name: "new-landing-landing___en"
  }, {
    path: "/franchise-directory/industries/:industry/:company",
    component: _5366e2c4,
    name: "franchise-directory-industries-industry-company___en"
  }, {
    path: "/franchise-directory/industries/:industry/:slug",
    component: _540400fa,
    name: "franchise-directory-industries-industry-slug___en"
  }, {
    path: "/new/landing/:landing/:page",
    component: _46ac1253,
    name: "new-landing-landing-page___en"
  }, {
    path: "/franchise-directory/industries/:industry/:company/:slug",
    component: _51b0417c,
    name: "franchise-directory-industries-industry-company-slug___en"
  }, {
    path: "/company-pages/:company",
    component: _4d804788,
    name: "company-pages-company___en"
  }, {
    path: "/new-landing/:page?",
    component: _20b28dc0,
    name: "new-landing-page___en"
  }, {
    path: "/top-franchises/:category?",
    component: _01b8f203,
    name: "top-franchises-category___en"
  }, {
    path: "/company-pages/:company?/:slug",
    component: _50ed8a18,
    name: "company-pages-company-slug___en"
  }, {
    path: "/",
    component: _02246db4,
    name: "index___en",
    children: [{
      path: "auth/login",
      component: _47238fea,
      name: "index-auth-login___en"
    }, {
      path: "auth/reset",
      component: _88edfa1e,
      name: "index-auth-reset___en"
    }, {
      path: "email/confirm-change",
      component: _29211ac3,
      name: "index-email-confirm-change___en"
    }, {
      path: "auth/verify/:userId?",
      component: _1d0d7a2e,
      name: "index-auth-verify-userId___en"
    }]
  }, {
    path: "/:landing",
    component: _d2bbca64,
    name: "landing___en"
  }, {
    path: "/:landing/:page",
    component: _0139412c,
    name: "landing-page___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
