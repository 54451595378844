import * as types from './mutation-types'

// state
export const state = () => ({
  franchiseId: null,
  businessSteps: [],
  businessStepsList: [],
})

// getters
export const getters = {
  franchiseId: state => state.franchiseId,
  businessSteps: state => state.businessSteps,
  businessStepsList: state => {
    let busienssSteps = state.businessSteps
    let busienssStepsNoGroups = []

    busienssSteps.forEach(group => {
      group.steps.forEach(step => {
        busienssStepsNoGroups.push({
          title: step.name,
          value: step.step_id,
          count_franchisee: step.count_franchisee,
        })
      })
    })
    return busienssStepsNoGroups
  },
  businessStepsListWithoutCounter: state => {
    let busienssSteps = state.businessSteps
    let busienssStepsNoGroups = []

    busienssSteps.forEach(group => {
      group.steps.forEach(step => {
        busienssStepsNoGroups.push({
          title: step.name,
          value: step.step_id,
          count_franchisee: step.count_franchisee,
        })
      })
    })
    return busienssStepsNoGroups
  },
}

export const mutations = {
  [types.FETCH_BUSINESS_STEPS] (state, businessSteps) {
    state.businessSteps = businessSteps
  },
  [types.FETCH_BUSINESS_STEPS_FAILURE] (state) {
    state.businessSteps = []
  },
}

export const actions = {
  async fetchBusinessSteps ({ commit, state }, payload) {
    try {
      let franchiseId = this.state.franchiseId
      let filterQuery = ''

      if (payload && payload.franchiseId) {
        franchiseId = payload.franchiseId
      }
      if (!franchiseId) {
        commit(types.FETCH_BUSINESS_STEPS_FAILURE)
        return
      }
      this.state.franchiseId = franchiseId

      if (payload?.filter || payload?.active_step) {
        filterQuery = `?`
      }

      if (payload?.filter) {
        payload.filter.forEach(item => {
          filterQuery += `&${item}=true`
        })
      }

      if (payload?.activeStep) {
        filterQuery += `&active_step=${payload.activeStep}`
      }

      const { data } = await this.$axios.get(`/api/v2/business-steps/${franchiseId}/with-groups${filterQuery}`)
      commit(types.FETCH_BUSINESS_STEPS, data.result)
    } catch (e) {
      console.log(e)
      commit(types.FETCH_BUSINESS_STEPS_FAILURE)
    }
  },
}

