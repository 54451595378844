import Vue from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
  position: "top-right",
  timeout: 3000,
  showCloseButtonOnHover: false,
  closeButton: false,
  icon: true,
};

Vue.use(Toast, options);
