import {mapFranchise} from "@/store/helper"
import {cloneDeep} from 'lodash/fp'
import * as types from '../../mutation-types'
import {MOBILE_DEFAULT_FILTER_OBJECT} from "@/store/constants"

// state
export const state = () => ({
  franchisesArr: [],
  paginationObj: {
    current_page: 1,
    per_page: 12,
    total: '',
  },
  filterObj: MOBILE_DEFAULT_FILTER_OBJECT,
  busy: false,
  addMode: false,
  franchiseIndex: null,
  scrollTop: 0,
})

// getters
export const getters = {
  franchisesArr: state => state.franchisesArr,
  paginationObj: state => state.paginationObj,
  filterObj: state => state.filterObj,
  busy: state => state.busy,
  addMode: state => state.addMode,
  franchiseIndex: state => state.franchiseIndex,
  scrollTop: state => state.scrollTop,
}

export const mutations = {
  [types.UPDATE_MOBILE_DIRECTORY_FRANCHISES_ARR](state, franchisesArr) {
    if (!state.addMode)
      state.franchisesArr = franchisesArr

    if (state.addMode)
      state.franchisesArr = [...state.franchisesArr, ...franchisesArr]
  },
  [types.UPDATE_MOBILE_DIRECTORY_PAGINATION_OBJ](state, paginationObj) {
    state.paginationObj = paginationObj
  },
  [types.UPDATE_MOBILE_DIRECTORY_FILTER_OBJ](state, filterObj) {
    state.filterObj = filterObj
  },
  [types.UPDATE_MOBILE_DIRECTORY_BUSY_STATUS](state, status) {
    state.busy = status
  },
  [types.UPDATE_MOBILE_DIRECTORY_CURRENT_PAGE](state, page) {
    state.paginationObj.current_page = page
  },
  [types.UPDATE_MOBILE_DIRECTORY_ADD_MODE_STATUS](state, toggle) {
    state.addMode = toggle
  },
  [types.UPDATE_MOBILE_DIRECTORY_FRANCHISE_INDEX](state, index) {
    state.franchiseIndex = index
  },
  [types.UPDATE_MOBILE_DIRECTORY_SCROLL_TOP](state, scrollTop) {
    state.scrollTop = scrollTop
  },
  [types.UPDATE_MOBILE_DIRECTORY_FRANCHISE_ACTION](state, isSelect) {
    state.franchisesArr[state.franchiseIndex].isUserSelect = isSelect
    state.franchisesArr[state.franchiseIndex].isUserSkip = !isSelect
  },
}

export const actions = {
  async fetchFranchises({commit, state}, addMode = false) {
    commit(types.UPDATE_MOBILE_DIRECTORY_BUSY_STATUS, true)
    commit(types.UPDATE_MOBILE_DIRECTORY_ADD_MODE_STATUS, addMode)

    try {
      const params = {
        page: state.paginationObj.current_page,
        per_page: state.paginationObj.per_page,
      }

      Object.keys(state.filterObj).forEach(key => {
        const item = state.filterObj[key]
        if (item === '')
          return

        if (
          key === 'industry' ||
          key === 'tags_id' ||
          key === 'states_id'
        ) {
          params[key] = []
          params[key].push(item)
          return
        }

        if (typeof item === "string") {
          params[key] = item
          return
        }

        if (item.min === '' && item.max === '')
          return

        params[key] = []
        params[key].push(item.min === '' ? 0 : item.min)

        if (item.max !== '')
          params[key].push(item.max)
      })

      const {data} = await this.$axios.get(`api/v2/businesses?sort[membership]=desc&sort[business_name]=asc`, {params})
      const franchisesArr = cloneDeep(data.result.data).map(item => mapFranchise(item))
      delete (data.result.data)
      commit(types.UPDATE_MOBILE_DIRECTORY_FRANCHISES_ARR, franchisesArr)
      commit(types.UPDATE_MOBILE_DIRECTORY_PAGINATION_OBJ, data.result)
    } catch (e) {
      console.log(e)
    }
    commit(types.UPDATE_MOBILE_DIRECTORY_ADD_MODE_STATUS, false)
    commit(types.UPDATE_MOBILE_DIRECTORY_BUSY_STATUS, false)
  },
  nextPage({commit, state}) {
    const currentPage = state.pagination.current_page
    commit(types.CHANGE_PAGE, currentPage + 1)
  },
  firstPage({commit}) {
    commit(types.CHANGE_PAGE, 1)
  },
  updateFilter({commit}, filterObj) {
    commit(types.UPDATE_MOBILE_DIRECTORY_FILTER_OBJ, filterObj)
  },
  resetFilter({commit}) {
    commit(types.UPDATE_MOBILE_DIRECTORY_FILTER_OBJ, MOBILE_DEFAULT_FILTER_OBJECT)
  },
  updateCurrentPage({commit}, page) {
    commit(types.UPDATE_MOBILE_DIRECTORY_CURRENT_PAGE, page)
  },
  updateFranchiseIndex({commit}, index) {
    commit(types.UPDATE_MOBILE_DIRECTORY_FRANCHISE_INDEX, index)
  },
  updateScrollTop({commit}, scrollTop) {
    commit(types.UPDATE_MOBILE_DIRECTORY_SCROLL_TOP, scrollTop)
  },
  updateActionFranchise({commit}, isSelect) {
    commit(types.UPDATE_MOBILE_DIRECTORY_FRANCHISE_ACTION, isSelect)
  },
}

