export default ({ store, redirect, query }) => {
  const user = store.getters['auth/user']

  if (user && user.isFranchisor){
    store.dispatch('auth/setPagePrivateStatus', true)
    return
  }

  if (user && user.isMasterAdmin && query.version_id && query.franchise_id){
    store.dispatch('auth/setPagePrivateStatus', true)
    return
  }

  redirect('/')
}
