export default function ({$axios, store, redirect}) {
  function refreshToken() {
    store.commit('auth/SET_REFRESHING_STATE', true)

    const refreshingCall = $axios.get('api/refresh').then(({data: {result}}) => {
      const token = result.token
      const remember = result.remember
      const expires = result.expires
      store.commit('auth/SAVE_TOKEN', {token, remember, expires})
      store.commit('auth/SET_REFRESHING_STATE', false)
      store.commit('auth/SET_REFRESHING_CALL', null)

      return Promise.resolve(true)
    })

    store.commit('auth/SET_REFRESHING_CALL', refreshingCall)

    return refreshingCall
  }

  $axios.onRequest(() => {
    const token = store.getters['auth/token']
    if (token)
      $axios.setToken(token, 'Bearer')
  })

  $axios.interceptors.response.use(response => response, error => {
    if (process.server)
      return Promise.reject(error)

    const status = error.response ? error.response.status : null
    const redirectPath = window?.cordova ? '/profile/logout':`/auth/logout?redirect=${encodeURIComponent('/auth/login?session=expired')}`

    if (Number(status) === 401 && !store.state.auth.isRefreshing) {
      if (store.state.auth.remember) {
        return refreshToken().then(() => {
          error.config.headers.Authorization = `Bearer ${store.state.auth.token}`
          error.config.baseURL = undefined
          return $axios.request(error.config)
        }).catch(() => {
          store.commit('auth/SET_REFRESHING_STATE', false)
          store.commit('auth/SET_REFRESHING_CALL', null)
          store.commit('mobile/franchisee/signUp/CHANGE_MOBILE_SIGNUP_FLOW_STEP', 0)
          redirect(redirectPath)
        })
      }

      store.commit('auth/SET_REFRESHING_STATE', false)
      store.commit('auth/SET_REFRESHING_CALL', null)
      store.commit('mobile/franchisee/signUp/CHANGE_MOBILE_SIGNUP_FLOW_STEP', 0)
      redirect(redirectPath)
    }

    return Promise.reject(error)
  })
}
