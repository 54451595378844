import {
  PAYMENT_MEMBERSHIP_LIST,
} from "@/store/constants"
import * as types from './mutation-types'


// state
export const state = () => ({
  levels: null,
  user: null,
  permissions: {
    chat: {
      access: true,
      message: null
    },
    chatDialog: {
      access: true,
      message: null
    },
    sendChatMessage: {
      access: true,
      message: null
    },
    sendChatFile: {
      access: true,
      message: null
    },
    approveStep: {
      access: true,
      message: null
    },
    addDocumentToStep: {
      access: true,
      message: null
    },
    editCompany: {
      access: true,
      message: null
    },
    budgetTool: {
      access: true,
      message: null
    },
    franchiseeProfile: {
      access: true,
      message: null
    },
    uploadLead: {
      access: true,
      message: null
    },
    CRM: {
      access: true,
      message: null
    },
  }
})

// getters
export const getters = {
  user: state => state.user,
  levels: state => state.levels,
  permissions: state => state.permissions,
}

// mutations
export const mutations = {
  [types.FETCH_PAYMENT] (state, payload) {
    state.levels = payload.levels;
    state.user = payload.user;
  },
  [types.FETCH_PAYMENT_PERMISSIONS] (state, payload) {
    const userInfo = {
      membership: payload.user.membership_level,
      signedContract: payload.user.signed_contract,
      expiredContract: payload.user.expired
    }

    Object.keys(rules).forEach(action => {
      Object.keys(rules[action]).forEach(ruleName => {
        const accessDeniedBy = {}

        if (rules[action][ruleName] !== '*' && userInfo[ruleName] !== rules[action][ruleName]) {
          if (ruleName !== 'membership') {
            accessDeniedBy[ruleName] = ruleName
          } else if (
            !rules[action][ruleName].includes(userInfo[ruleName]) &&
            rules[action][ruleName] !== userInfo[ruleName]
          ) {
            accessDeniedBy[ruleName] = ruleName
          }
        }

        if (Object.keys(accessDeniedBy).length) {
          let message = 'Access Denied.'

          if (accessDeniedBy.hasOwnProperty('signedContract')) {
            message = this.app.i18n.t('accountFranchisor.accessMessages.notSignedContract')
          } else if (accessDeniedBy.hasOwnProperty('membership')) {
            message = this.app.i18n.t('accountFranchisor.accessMessages.upgradeMembership')
          }

          state.permissions[action].access = false
          state.permissions[action].message = message
        }
      })
    })
  }
}

// actions
export const actions = {
  async fetchPayment ({ commit, state }, userId) {
    try {

      const { data } = await this.$axios.get(`/api/v2/payment/${userId}/info`)
      const payment = mapPayment(data.result)

      commit(types.FETCH_PAYMENT, payment)
      commit(types.FETCH_PAYMENT_PERMISSIONS, payment)
    } catch (e) {
      commit(types.FETCH_PAYMENT, e)
    }
  }
}

const mapPayment = (item) => {
  const paymentMapped = {
    levels: item.levels,
    user: {
      expire_on: item.user.expire_on,
      expired: item.user.expired,
      email_verify: item.user.email_verify,
      membership_level: item.user.membership_level,
      membership_level_title: item.user.membership_level_title,
      signed_contract: item.user.signed_contract,
      provided_payment: item.user.provided_payment
    }
  }

  if (paymentMapped.user.membership_level === PAYMENT_MEMBERSHIP_LIST.Later) {
    paymentMapped.user.expired = false
  }

  return paymentMapped
}

const rules = {
  chat: {
    signedContract: '*',
    expiredContract: '*',
    membership: '*',
  },
  chatDialog: {
    signedContract: true,
    expiredContract: '*',
    membership: [
      PAYMENT_MEMBERSHIP_LIST.Gold,
      PAYMENT_MEMBERSHIP_LIST.Business,
    ],
  },
  sendChatMessage: {
    signedContract: true,
    expiredContract: '*',
    membership: [
      PAYMENT_MEMBERSHIP_LIST.Gold,
      PAYMENT_MEMBERSHIP_LIST.Business,
    ],
  },
  sendChatFile: {
    signedContract: true,
    expiredContract: '*',
    membership: [
      PAYMENT_MEMBERSHIP_LIST.Gold,
      PAYMENT_MEMBERSHIP_LIST.Business,
    ],
  },
  approveStep: {
    signedContract: true,
    expiredContract: '*',
    membership: [
      PAYMENT_MEMBERSHIP_LIST.Gold,
      PAYMENT_MEMBERSHIP_LIST.Business,
    ],
  },
  addDocumentToStep: {
    signedContract: true,
    expiredContract: '*',
    membership: [
      PAYMENT_MEMBERSHIP_LIST.Gold,
      PAYMENT_MEMBERSHIP_LIST.Business,
    ],
  },
  editCompany: {
    signedContract: '*',
    expiredContract: '*',
    membership: [
      PAYMENT_MEMBERSHIP_LIST.Gold,
      PAYMENT_MEMBERSHIP_LIST.Business,
    ],
  },
  budgetTool: {
    signedContract: '*',
    expiredContract: '*',
    membership: [
      PAYMENT_MEMBERSHIP_LIST.Gold,
      PAYMENT_MEMBERSHIP_LIST.Business,
    ],
  },
  franchiseeProfile: {
    signedContract: true,
    expiredContract: '*',
    membership: [
      PAYMENT_MEMBERSHIP_LIST.Gold,
      PAYMENT_MEMBERSHIP_LIST.Business,
    ],
  },
  uploadLead: {
    signedContract: true,
    expiredContract: '*',
    membership: [
      PAYMENT_MEMBERSHIP_LIST.Gold,
      PAYMENT_MEMBERSHIP_LIST.Business,
    ],
  },
  CRM: {
    signedContract: true,
    expiredContract: '*',
    membership: [
      PAYMENT_MEMBERSHIP_LIST.Gold,
      PAYMENT_MEMBERSHIP_LIST.Business,
    ],
  },
}
