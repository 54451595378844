import * as types from '../../mutation-types'

export const state = () => ({
	currentStep:0,
	currentPreviewStep:0,
	flowStarted: false,
	flowPreviewStarted: false,
})

// getters
export const getters = {
	currentStep: state => state.currentStep,
  currentPreviewStep: state => state.currentPreviewStep,
	flowStarted: state => state.flowStarted,
  flowPreviewStarted: state => state.flowPreviewStarted,
}

export const mutations = {
	[types.CHANGE_MOBILE_SIGNUP_FLOW_STEP] (state, step) {
		state.currentStep = step
	},
  [types.CHANGE_MOBILE_SIGNUP_FLOW_PREVIEW_STEP] (state, step) {
    state.currentPreviewStep = step
  },
	[types.CHANGE_MOBILE_STATUS_FLOW] (state, status) {
		state.flowStarted = status
	},
  [types.CHANGE_MOBILE_STATUS_FLOW_PREVIEW] (state, status) {
    state.flowPreviewStarted = status
  },
}

export const actions = {
	nextStep({state,commit}){
		commit(types.CHANGE_MOBILE_SIGNUP_FLOW_STEP, state.currentStep+1)
	},
  nextStepPreview({state,commit}){
    commit(types.CHANGE_MOBILE_SIGNUP_FLOW_PREVIEW_STEP, state.currentPreviewStep+1)
  },
	changeStep({commit},stepIndex){
		commit(types.CHANGE_MOBILE_SIGNUP_FLOW_STEP, stepIndex)
	},
  changeStepPreview({commit},stepIndex){
    commit(types.CHANGE_MOBILE_SIGNUP_FLOW_PREVIEW_STEP, stepIndex)
  },
	prevStep({state,commit}){
		if (state.currentStep === 0)
			return

		commit(types.CHANGE_MOBILE_SIGNUP_FLOW_STEP, state.currentStep-1)
	},
	resetStep({commit}){
		commit(types.CHANGE_MOBILE_STATUS_FLOW, false)
		commit(types.CHANGE_MOBILE_SIGNUP_FLOW_STEP, 0)
	},
	activateFlow({commit}){
		commit(types.CHANGE_MOBILE_STATUS_FLOW, true)
	},
  activateFlowPreview({commit}){
    commit(types.CHANGE_MOBILE_STATUS_FLOW_PREVIEW, true)
  },
  deactivateFlowPreview({commit}){
    commit(types.CHANGE_MOBILE_STATUS_FLOW_PREVIEW, false)
    commit(types.CHANGE_MOBILE_SIGNUP_FLOW_PREVIEW_STEP, 0)
  },

}
