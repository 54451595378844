import Cookies from 'js-cookie';
import * as types from './mutation-types';
import {USER_TYPE_FRANCHISEE, USER_TYPE_FRANCHISOR, USER_TYPE_MASTER_ADMIN} from './constants';

// state
export const state = () => ({
  user: null,
  token: null,
  expires: null,
  remember: false,
  isRefreshing: false,
  user_franchise: null,
  redirectToComparisons: false,
  isVerifiedFranchise: true,
  isMobile: false,
  isPrivatePage: false,
});

// getters
export const getters = {
  user: (state) => state.user,
  check: (state) => state.user !== null,
  token: (state) => state.token,
  expires: (state) => state.expires,
  remember: (state) => state.remember,
  isRefreshing: (state) => state.isRefreshing,
  isVerifiedFranchise: (state) => state.isVerifiedFranchise,
  user_franchise: (state) => state.user_franchise,
  redirectToComparisons: (state) => state.redirectToComparisons,
  isPrivatePage: (state) => state.isPrivatePage,
};

// mutations
export const mutations = {
  [types.SET_TOKEN](state, {token, remember, expires}) {
    state.token = token
    state.remember = remember
    state.expires = expires
  },
  [types.SET_REFRESHING_STATE](state, isRefreshing) {
    state.isRefreshing = isRefreshing
  },
  [types.SET_REFRESHING_CALL](state, call) {
    state.refreshingCall = call
  },
  [types.SAVE_TOKEN](state, {token, remember, expires}) {
    state.token = token
    state.remember = remember
    state.expires = expires

    if (state.isMobile) {
      localStorage.userToken = token
      localStorage.userRemember = remember
      localStorage.userExpires = expires
    }

    Cookies.set('token', token, {expires: 180})
    Cookies.set('remember', remember, {expires: 180})
    Cookies.set('expires', expires, {expires: 180})

    if (typeof token !== "undefined" && token !== null)
      return

    Cookies.remove('token')
    Cookies.remove('remember')
    Cookies.remove('expires')
  },
  [types.FETCH_USER_SUCCESS](state, {user}) {
    state.user = user
  },
  [types.SET_USER_TYPE](state, user) {
    state.user.isFranchisor = user.user_type === USER_TYPE_FRANCHISOR
    state.user.isFranchisee = user.user_type === USER_TYPE_FRANCHISEE
    state.user.isMasterAdmin = user.user_type === USER_TYPE_MASTER_ADMIN
  },
  [types.LOGOUT](state) {
    Cookies.remove('token')
    Cookies.remove('remember')
    Cookies.remove('expires')

    if (state.isMobile) {
      localStorage.removeItem('userToken')
      localStorage.removeItem('userRemember')
      localStorage.removeItem('userExpires')
    }

    state.token = null
    state.remember = null
    state.expires = null
    state.user = null
  },
  [types.UPDATE_USER](state, user) {
    state.user = user
  },
  [types.UPDATE_USER_FRANCHISE](state, {data}) {
    state.user_franchise = data
  },
  [types.UPDATE_REDIRECT_TO_COMPARISONS](state, status) {
    state.redirectToComparisons = status
  },
  [types.UPDATE_VERIFIED_STATUS_USER](state, toggle) {
    state.isVerifiedFranchise = toggle
  },
  [types.UPDATE_STATUS_MOBILE](state, toggle) {
    state.isMobile = toggle
  },
  [types.UPDATE_STATUS_PRIVATE_PAGE](state, status) {
    state.isPrivatePage = status
  },
};

// actions
export const actions = {
  saveToken({commit, dispatch}, payload) {
    commit(types.SAVE_TOKEN, payload)
  },
  afterSignInFranchiseRedirectToComparisons({commit}) {
    commit(types.UPDATE_REDIRECT_TO_COMPARISONS, true)
  },
  async fetchUser({state, commit}) {
    try {
      const {data} = await this.$axios.get('/api/user')

      commit(types.FETCH_USER_SUCCESS, {user: data})
      commit(types.SET_USER_TYPE, data)
      commit(types.UPDATE_VERIFIED_STATUS_USER, data.email_verified_at !== null)
    } catch (e) {
      if (Number(e.response?.status) === 401 && state.remember)
        return
      commit(types.LOGOUT)
    }
  },
  async refreshToken({commit}) {
    try {
      const {data} = await this.$axios.get('/api/refresh')
      commit(types.SAVE_TOKEN, {
        token: data.result.token,
        remember: data.result.remember,
        expires: data.result.expires
      })
    } catch (e) {
      commit(types.LOGOUT)
    }
  },
  async fetchUserFranchise({state, commit}) {
    if (state.user !== null && state.user.user_type !== USER_TYPE_FRANCHISOR)
      return;

    const {data} = await this.$axios.get(`/api/v1/user-franchise`);

    commit(types.UPDATE_USER_FRANCHISE, {data});
  },
  updateUser({commit}, {user}) {
    commit(types.UPDATE_USER, user);
    commit(types.SET_USER_TYPE, user);
  },
  async logout({commit}) {
    try {
      await this.$axios.post('/api/logout');
    } catch (e) {
    }

    commit(types.LOGOUT);
  },
  async fetchOauthUrl(ctx, {provider, userType}) {
    const params = {}
    if (userType) {
      params.userType = userType
    }
    const {data} = await this.$axios.post(`/api/oauth/${provider}`, null, {
      params
    });

    return data.url;
  },
  setPagePrivateStatus({commit}, status) {
    commit(types.UPDATE_STATUS_PRIVATE_PAGE, status)
  }
};
