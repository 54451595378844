import {cloneDeep} from 'lodash/fp'
import * as types from './mutation-types'

// state
export const state = () => ({
  franchiseList: [],
  franchiseListCompetitors: [],
  filter: {
    industry: '',
    investment: {
      min: null,
      max: null
    },
    number_of_locations: {
      min: null,
      max: null
    },
    business_name: '',
    allowed_states_id: '',
    allowed_tags_id: '',
    franchise_fee: {
      min: null,
      max: null
    },
    franchising_since: '',
    established: '',
    average_revenue: {
      min: null,
      max: null
    },
    membership: '',
    fdd_available: null,
    file_item_7_available: null,
    file_item_19_available: null,
    like: '',
    in_comparison: '',
    video: null
  },
  defaultFilter: {
    industry: '',
    investment: {
      min: null,
      max: null
    },
    number_of_locations: {
      min: null,
      max: null
    },
    business_name: '',
    allowed_states_id: '',
    allowed_tags_id: '',
    franchise_fee: {
      min: null,
      max: null
    },
    franchising_since: '',
    established: '',
    average_revenue: {
      min: null,
      max: null
    },
    membership: '',
    fdd_available: null,
    file_item_7_available: null,
    file_item_19_available: null,
    like: '',
    in_comparison: '',
    video: null
  },
  pagination: {
    current_page: 1,
    first: '',
    from: 1,
    last: '',
    last_page: 33,
    next: '',
    path: '',
    per_page: 12,
    prev: null,
    to: 0,
    total: 0
  },
  sort: {
    field: 'business_name',
    direction: 'asc',
  },
  itemsState: false, // default state card
  liked: [],
  comparisons: [],
  progress: [],
  clicked: false,
  search_modal: false,
  filterCompetitors: {
    industry: [],
  },
  busy: false,
})

// getters
export const getters = {
  filter: state => state.filter,
  defaultFilter: state => state.defaultFilter,
  filterCompetitors: state => state.filterCompetitors,
  no_autofilter_fields: state => state.no_autofilter_fields,
  franchiseList: state => state.franchiseList,
  franchiseListCompetitors: state => state.franchiseListCompetitors,
  pagination: state => state.pagination,
  liked: state => state.liked,
  comparisons: state => state.comparisons,
  progress: state => state.progress,
  clicked: state => state.clicked,
  search_modal: state => state.search_modal,
  sort: state => state.sort,
  busy: state => state.busy,
}

// mutations
export const mutations = {
  [types.FETCH_BUSINESS_SUCCESS](state, franchiseList) {
    state.franchiseList = franchiseList
  },
  [types.SET_BUSINESS_COMPETITORS](state, franchiseList) {
    state.franchiseListCompetitors = franchiseList
  },
  [types.FETCH_BUSINESS_FAILURE](state) {
    state.data = []
  },
  [types.LIKE_ITEM](state, payload) {
    state.liked.push(payload)
    payload.like = 1
  },
  [types.DISLIKE_ITEM](state, payload) {
    const likedIdx = state.liked.findIndex(el => el.id === payload.id)

    state.liked.splice(likedIdx, 1)
    payload.like = 0
  },
  [types.COMPARISON](state, payload) {
    if (state.data[payload].in_comparison === 0) {
      state.data[payload].in_comparison = 1
    } else {
      state.data[payload].in_comparison = 0
    }
  },
  [types.SET_MAIN_FILTER_PAGINATION](state, pagination) {
    state.pagination = Object.assign(state.pagination, pagination)
  },
  [types.SET_FILTER_COMPETITORS](state, {industry}) {
    state.filterCompetitors.industry = []
    if (industry === '')
      return
    state.filterCompetitors.industry.push(industry)
  },
  [types.SET_ITEMS_STATE](state, itemsState) {
    state.itemsState = itemsState
  },
  [types.SET_SORTING](state, sort) {
    state.sort = Object.assign(state.sort, sort)
  },
  [types.FETCH_LIKES_SUCCESS](state, payload) {
    state.liked = payload
  },
  [types.FETCH_LIKES_FAILURE](state) {
    state.likedFranchises = null
  },
  [types.FETCH_COMPARISONS_SUCCESS](state, payload) {
    state.comparisons = payload
  },
  [types.FETCH_COMPARISONS_FAILURE](state) {
    state.comparisons = []
  },
  [types.ADD_COMPARISON_SUCCESS](state, payload) {
    state.comparisons.push(payload)
    payload.in_comparison = 1
  },
  [types.REMOVE_COMPARISON_SUCCESS](state, payload) {
    const comparisonIdx = state.comparisons.findIndex(el => el.id === payload.id)

    state.comparisons.splice(comparisonIdx, 1)
    payload.in_comparison = 0
  },
  [types.TOGGLE_COMPARISONS_FAILURE](state, payload) {
    console.log('error', types.TOGGLE_COMPARISONS_FAILURE)
  },
  [types.SET_FILTER_ITEM](state, {key, value}) {
    if (typeof value === 'object' && value !== null) {
      Object.assign(state.filter[key], value)
    } else {
      state.filter[key] = value
    }
  },
  [types.FETCH_PROGRESS_SUCCESS](state, payload) {
    state.progress = payload
  },
  [types.FETCH_PROGRESS_FAILURE](state) {
    state.progress = []
  },
  [types.FETCH_BUTTON_CLICK_SUCCESS](state, data) {
    state.clicked = data
  },
  [types.FETCH_FETCH_BUTTON_CLICK_FAILURE](state) {
    state.clicked = false
  },
  [types.SET_CLOSE_FRANCHISE_NAV_MODAL](state, payload) {
    state.search_modal = payload
  },
  [types.FETCH_DIRECTORY_FRANCHISE_BUSY](state, status) {
    state.busy = status
  },
  [types.SET_DEFAULT_STATE_FILTER](state) {
    state.filter = cloneDeep(state.defaultFilter)
  }
}

// actions
export const actions = {
  async fetchCompany({commit, state}) {
    try {
      const {data} = await this.$axios.post(`/api/v1/search?page=1&sort=business_name&company_page_published=true`)
      const pagination = {...data}

      const businesses = data.data.map(b => {
        return {
          id: b.franchise_id,
          business_name: b.business_name.attribute_value_string,
          selfRatings: b.self_ratings,
          surveyRatings: b.survey_ratings,
          membership: b.membership.attribute_value_string,
          imageUrl: b.logo,
          itemUrl: `/franchise-directory/industries/${b.slug_industry.attribute_value_string}/${b.slug}`,
          itemCompanyUrl: `/company-pages/${b.slug}`,
          fddUrl: b.files.fdd ? b.files.fdd.url : null,
          shortDesc: b.short_descr.attribute_value_string,
          investment: b.investment.attribute_value_numeric_low,
          average_revenue: b.average_revenue.attribute_value_string,
          franchise_fee: b.franchise_fee.attribute_value_numeric_low,
          franchiseNumber: b.number,
          like: b.like,
          in_comparison: b.in_comparison,
          slug: b.slug,
          number_of_locations: Number.isNaN(Number(b.number_of_locations.attribute_value_string)) ? '' : b.number_of_locations.attribute_value_string,
          established: b.established.attribute_value_string,
          flow_status: b.flow_status,
        }
      })

      commit(types.FETCH_BUSINESS_SUCCESS, businesses)
      commit(types.SET_MAIN_FILTER_PAGINATION, pagination)

    } catch (e) {
      commit(types.FETCH_BUSINESS_FAILURE)
    }
  },
  async fetchBusiness({commit, state}) {
    commit(types.FETCH_DIRECTORY_FRANCHISE_BUSY, true)
    const params = {
      page: state.pagination.current_page,
      per_page: state.pagination.per_page,
    }

    Object.keys(state.filter).forEach(key => {
      const item = state.filter[key]

      if (item === '' || item === null)
        return

      if (key === 'industry') {
        params.industry = []
        params.industry.push(item)
      }

      if (key === 'business_name') {
        params.business_name = item
      }

      if (key === 'allowed_tags_id') {
        params.tags_id = []
        params.tags_id.push(item)
      }

      if (key === 'investment') {
        if (item.min === null && item.max === null)
          return

        params.investment = []
        params.investment.push(item.min ?? 0)

        if (item.max !== null)
          params.investment.push(item.max)

      }

      if (key === 'number_of_locations') {
        if (item.min === null && item.max === null)
          return

        params.number_of_locations = []
        params.number_of_locations.push(item.min ?? 0)

        if (item.max !== null)
          params.number_of_locations.push(item.max)
      }

      if (key === 'allowed_states_id') {
        params.states_id = []
        params.states_id.push(item)
      }

      if (key === 'franchise_fee') {
        if (item.min === null && item.max === null)
          return

        params.franchise_fee = []
        params.franchise_fee.push(item.min ?? 0)

        if (item.max !== null)
          params.franchise_fee.push(item.max)
      }

      if (key === 'established') {
        params.established = item
      }


      if (key === 'franchising_since') {
        params.franchising_since = item
      }


      if (key === 'average_revenue') {
        if (item.min === null && item.max === null)
          return

        params.average_revenue = []
        params.average_revenue.push(item.min ?? 0)

        if (item.max !== null)
          params.average_revenue.push(item.max)
      }

      if (key === 'file_available') {
        if (item)
          params.fdd_available = +item
      }

      if (key === 'file_item_7_available') {
        if (item)
          params.item_7_available = +item
      }

      if (key === 'file_item_19_available') {
        if (item)
          params.item_19_available = +item
      }

    })

    try {
      const {data} = await this.$axios.get(`api/v2/businesses?sort[top_order]=desc&sort[membership]=desc&sort[${state.sort.field}]=${state.sort.direction}`, {params})

      const pagination = cloneDeep(data.result)
      const franchiseList = cloneDeep(data.result.data)

      commit(types.FETCH_BUSINESS_SUCCESS, franchiseList)
      commit(types.SET_MAIN_FILTER_PAGINATION, pagination)
    } catch (e) {
      commit(types.FETCH_BUSINESS_FAILURE)
    }
    commit(types.FETCH_DIRECTORY_FRANCHISE_BUSY, false)
  },
  async fetchBusinessCompetitors({commit, state}) {
    const params = cloneDeep(state.filterCompetitors)

    params.page = 1
    params.per_page = 4


    try {
      const {data} = await this.$axios.get(`api/v2/businesses?sort[number_of_locations]=desc`, {params})

      commit(types.SET_BUSINESS_COMPETITORS, data.result.data)
    } catch (e) {

    }
  },
  async fetchLikes({commit}) {
    try {
      const {data} = await this.$axios.get(`/api/v1/info/get/like`)
      const likes = [...data.data]
      const liked = likes.map(b => {
        return {
          id: b.franchise_id,
          business_name: b.business_name.attribute_value_string,
          selfRatings: b.self_ratings,
          surveyRatings: b.survey_ratings,
          membership: b.membership.attribute_value_string,
          imageUrl: b.logo,
          itemUrl: `/franchise-directory/industries/${b.slug_industry.attribute_value_string}/${b.slug}`,
          itemCompanyUrl: `/company-pages/${b.slug}`,
          fddUrl: b.files.fdd ? b.files.fdd.url : null,
          shortDesc: b.short_descr.attribute_value_string,
          investment: b.investment.attribute_value_numeric_low,
          average_revenue: b.average_revenue.attribute_value_string,
          franchise_fee: b.franchise_fee.attribute_value_numeric_low,
          franchiseNumber: b.number,
          like: b.like,
          in_comparison: b.in_comparison,
          slug: b.slug,
          number_of_locations: b.number_of_locations.attribute_value_string,
          established: b.established.attribute_value_string,
          flow_status: b.flow_status,
        }
      })
      liked.sort((a, b) => {
        const memershipA = a.membership.toUpperCase()
        const memershipB = b.membership.toUpperCase()
        if (memershipA < memershipB) {
          return 1
        }
        if (memershipA > memershipB) {
          return -1
        }
        return 0
      })

      commit(types.FETCH_LIKES_SUCCESS, liked)
    } catch (e) {
      commit(types.FETCH_LIKES_FAILURE, e)
    }
  },
  async toggleLike({commit}, item) {
    if (item.like !== 1) {
      commit(types.LIKE_ITEM, item)
    } else {
      commit(types.DISLIKE_ITEM, item)
    }
    try {
      const {data} = await this.$axios.get(`/api/v1/action/${item.id}/like/`)
      return data
    } catch (e) {
      console.log(e)
    }
  },
  async fetchComparisons({commit, state}) {
    try {
      const {data} = await this.$axios.get(`/api/v1/info/get/compare`)
      const comparisons = data.data.map(item => {
        return {
          id: item.franchise_id,
          slug: item.slug,
          business_name: item.business_name.attribute_value_string,
          established: item.established.attribute_value_string,
          rating: item.rating,
          membership: item.membership.attribute_value_string,
          imageUrl: item.logo,
          itemUrl: `/franchise-directory/industries/${item.slug_industry.attribute_value_string}/${item.slug}`,
          itemCompanyUrl: `/company-pages/${item.slug}`,
          fddUrl: item.files.fdd ? item.files.fdd.url : null,
          item7Url: item.files.item_7 ? item.files.item_7.url : null,
          item19Url: item.files.item_19 ? item.files.item_19.url : null,
          franchiseesUrl: item.files.franchisees ? item.files.franchisees.url : null,
          shortDesc: item.short_descr.attribute_value_string,
          investment_min: item.investment.attribute_value_numeric_low,
          investment_max: item.investment.attribute_value_numeric_high,
          average_revenue: item.average_revenue.attribute_value_string,
          gross_profit: item.gross_profit.attribute_value_string,
          presentation: item.files.presentation ? item.files.presentation.url : '',
          promo_video: item.video.attribute_value_string,
          franchise_fee: `${item.franchise_fee.attribute_value_numeric_low} USD`,
          franchise_fee_min: item.franchise_fee.attribute_value_numeric_low,
          franchise_fee_max: item.franchise_fee.attribute_value_numeric_high,
          franchiseNumber: item.number,
          like: item.like,
          in_comparison: item.in_comparison,
          franchising_since: item.franchising_since.attribute_value_string,
          logo: item.logo,
          main_royalty_fee: item.main_royalty_fee.attribute_value_string ? item.main_royalty_fee.attribute_value_string : '',
          royalty_percent: item.main_royalty_fee_percent.attribute_value_string,
          public_website_business_permalink: item.public_website_business_permalink ? item.public_website_business_permalink.attribute_value_string : '',
          naics: item.NAICS.attribute_value_string ? item.NAICS.attribute_value_string : '',
          minimum_credit_score: item.minimum_credit_score.attribute_value_string ? item.minimum_credit_score.attribute_value_string : '',
          minimum_liquid_assets: item.minimum_liquid_assets.attribute_value_string ? item.minimum_liquid_assets.attribute_value_string : '',
          total_net_worth: item.total_net_worth.attribute_value_string ? item.total_net_worth.attribute_value_string : '',
          total_like: item.total_like ? item.total_like : 0,
          number_of_locations: Number.isNaN(Number(item.number_of_locations.attribute_value_string)) ? '' : item.number_of_locations.attribute_value_string,
          flow_status: item.flow_status ? item.flow_status : '',
        }
      })

      comparisons.sort((a, b) => {
        const memershipA = a.membership.toUpperCase()
        const memershipB = b.membership.toUpperCase()
        if (memershipA < memershipB) {
          return 1
        }
        if (memershipA > memershipB) {
          return -1
        }
        return 0
      })
      commit(types.FETCH_COMPARISONS_SUCCESS, comparisons)
    } catch (e) {
      commit(types.FETCH_COMPARISONS_FAILURE, e)
    }
  },
  async toggleComparison({commit}, item) {
    if (item.in_comparison !== 1) {
      commit(types.ADD_COMPARISON_SUCCESS, item)
    } else {
      commit(types.REMOVE_COMPARISON_SUCCESS, item)
    }

    try {
      const {data} = await this.$axios.get(`/api/v1/action/${item.id}/compare`)

      return data
    } catch (e) {
      commit(types.TOGGLE_COMPARISONS_FAILURE, e)
    }
  },
  async comparisonPlus({commit, state}, dataIndex = -1, franchiseId = '') {
    let Id
    if (franchiseId) {
      Id = franchiseId
    } else {
      Id = state.data[dataIndex].id
    }
    try {
      await this.$axios.get(`/api/v1/action/${Id}/compare/`)
    } catch (e) {
      console.log(e)
    }
    commit(types.COMPARISON, dataIndex)
  },
  async fetchProgress({commit, state}) {
    try {
      const {data} = await this.$axios.get(`/api/v1/business-client-steps/franchisee-all-steps`)
      commit(types.FETCH_PROGRESS_SUCCESS, data)
    } catch (e) {
      commit(types.FETCH_PROGRESS_FAILURE, e)
    }

  },
  async fetchClick({commit}, data) {
    try {
      commit(types.FETCH_BUTTON_CLICK_SUCCESS, data)
    } catch (e) {
      commit(types.FETCH_FETCH_BUTTON_CLICK_FAILURE, e)
    }
  },
  updateIndustryFromCompanyPage({commit}, industry) {
    commit(types.SET_FILTER_ITEM, industry)
    commit(types.SET_MAIN_FILTER_PAGINATION, {
      current_page: 1
    })
  },
  updateFilterCompetitors({commit, state}, {industry}) {
    commit(types.SET_FILTER_COMPETITORS, {industry})
  },
  clearFilter({commit}) {
    commit(types.SET_DEFAULT_STATE_FILTER)
  }
}
