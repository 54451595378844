/* eslint-disable no-param-reassign */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import {
  SET_LOADING_NOTIFICATIONS,
  SET_NOTIFICATIONS_PAGINATION,
  SET_OR_APPEND_NOTIFICATIONS,
  SET_NOTIFICATIONS_UNREAD_COUNT,
  APPEND_NOTIFICATION,
  SET_READ_NOTIFICATION,
} from './mutation-types';

// state
export const state = () => ({
  notifications: [],
  notificationsUnreadCount: 0,
  notificationsLoading: false,
  notificationsPending: false,
  notificationsPagination: {
    current_page: 1,
  },
});

// getters
export const getters = {
  notifications: (state) => state.notifications,
  notificationsUnreadCount: (state) => state.notificationsUnreadCount,
  notificationsLoading: (state) => state.notificationsLoading,
  notificationsPending: (state) => state.notificationsPending,
  notificationsPagination: (state) => state.notificationsPagination,
};

// mutations
export const mutations = {
  /**
   * Set or append notifications
   * @param state
   * @param {Array} notifications
   */
  [SET_OR_APPEND_NOTIFICATIONS](state, notifications) {
    const currentPage = state.notificationsPagination.current_page;

    if (currentPage > 1) {
      // append data
      notifications.forEach((el) => state.notifications.push(el));
    } else {
      // set data
      state.notifications = notifications;
    }
  },
  /**
   * Set unread notification count
   * @param state
   * @param {Number} unreadCount
   */
  [SET_NOTIFICATIONS_UNREAD_COUNT](state, unreadCount) {
    state.notificationsUnreadCount = unreadCount;
  },
  /**
   * Set read notification
   * @param state
   * @param {Object} notification
   */
  [SET_READ_NOTIFICATION](state, notification) {
    notification.read_at = new Date();
  },
  /**
   * Loading data indicator
   * @param state
   * @param {Boolean} loading
   */
  [SET_LOADING_NOTIFICATIONS](state, loading) {
    state.notificationsLoading = loading;
  },
  /**
   * Set notification pagination
   * @param state
   * @param {Object} pagination
   */
  [SET_NOTIFICATIONS_PAGINATION](state, pagination) {
    Object.assign(state.notificationsPagination, pagination);
  },
  /**
   * Append notification
   * @param state
   * @param {Object} notification
   */
  [APPEND_NOTIFICATION](state, notification) {
    state.notifications.unshift(notification);
  },
};

// actions
export const actions = {
  /**
   * Load all notifications from api with pagination
   * @param commit
   * @param state
   */
  async fetchNotifications({ commit, state }) {
    commit(SET_LOADING_NOTIFICATIONS, true);
    const page = state.notificationsPagination.current_page;
    try {
      const { data } = await this.$axios.get(`/api/v1/notifications?page=${page}`);

      commit(SET_OR_APPEND_NOTIFICATIONS, data.data);
      commit(SET_NOTIFICATIONS_UNREAD_COUNT, data.count_unread);
      commit(SET_NOTIFICATIONS_PAGINATION, data);
    } catch (e) {
      console.log(e);
    }
    commit(SET_LOADING_NOTIFICATIONS, false);
  },
  /**
   * Set read status notification
   * @param commit
   * @param state
   * @param {Object} notification
   */
  async readNotification({ commit, state }, notification) {
    try {
      await this.$axios.get(`/api/v1/notifications/${notification.id}/read`);

      commit(SET_READ_NOTIFICATION, notification);
      commit(SET_NOTIFICATIONS_UNREAD_COUNT, Number(state.notificationsUnreadCount - 1));
    } catch (e) {
      console.log(e);
    }
  },
};
