import {cookieFromRequest, stringToBoolean} from '~/utils'

export const actions = {
  nuxtServerInit({commit, state}, {req}) {
    const token = state.auth.token ? state.auth.token : cookieFromRequest(req, 'token') ?? null
    const remember = stringToBoolean(cookieFromRequest(req, 'remember')) ?? null
    const expires = state.auth.expires ? parseInt(state.auth.expires, 10) : parseInt(cookieFromRequest(req, 'expires'), 10) ?? null
    commit('auth/SET_TOKEN', {token, remember, expires})
  },
  nuxtClientInit({commit, state}) {
    const token = state.auth.token
    const remember = state.auth.remember
    const expires = state.auth.expires

    commit('auth/SAVE_TOKEN', {token, remember, expires})
  }
}
