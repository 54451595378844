import * as types from './mutation-types'

// State
export const state = () => ({
    busy: false,
    dataItem: {},
    dataArr: [],
    filter: [],
    alphabetFilterArr: [],
    alphabetSelectLetter:'',
    search: '',
    pagination: {
        current_page: 1,
        per_page: 20
    },
    sort: {
        by: 'business_name',
        desc: ''
    },
    ignore_published: 1,
    company: {},

})

// Helper
function changeMappingCompany(company) {
    return {
        id: company.franchise_id,
        business_name: company.business_name.attribute_value_string,
        logo: company.logo,
        number_of_locations: company.number_of_locations.attribute_value_string,
        company_page_published: company.company_page_published.attribute_value_string,
        industry: company.industry.attribute_value_string,
        home_url: `/company-pages/${company.slug}`,
    }
}

// Getters
export const getters = {
    dataItem: state => state.dataItem,
    dataArr: state => state.dataArr,
    filter: state => state.filter,
    search: state => state.search,
    pagination: state => state.pagination,
    sort: state => state.sort,
    busy: state => state.busy,
    alphabetFilterArr: state => state.alphabetFilterArr,
    company: state => state.company,
}

// Mutations
export const mutations = {
    [types.FETCH_BUSINESS_SUCCESS](state, data) {
        state.dataArr = data
    },
    [types.FETCH_BUSINESS_FAILURE](state) {
        state.dataArr = []
    },
    [types.CLEAR_DATA_ARR](state) {
        state.dataArr = []
    },
    [types.SET_FILTER_ALPHABET_DATA](state, data) {
        state.alphabetFilterArr = data
    },
    [types.SET_SEARCH_VALUE](state, value) {
        state.search = value;
        if (value !== '')
            state.alphabetSelectLetter = value[0];
    },
    [types.CHANGE_FILTER_ALPHABET_ITEM](state, letter) {
        state.alphabetSelectLetter = letter;
    },
    [types.SET_SORTING](state, sort) {
        state.sort.by = sort.by ?? state.sort.by;
        state.sort.desc = sort.desc ? '-' : '';
    },
    [types.SET_MAIN_FILTER_PAGINATION](state, pagination) {
        state.pagination = Object.assign(state.pagination, pagination)
    },
    [types.SET_IGNORE_PUBLICATION_STATUS](state, status) {
        state.ignore_published = status
    },
    [types.SET_FILTER_ITEM](state, {key, value}) {
        state.filter.push({key, value});
    },
    [types.SET_FETCH_DATA](state, status) {
        state.busy = status;
    },

    [types.FETCH_COMPANY_SUCCESS](state, data) {
        state.company = data;
    },

    [types.FETCH_COMPANY_FAILURE](state) {
      state.company = {};
    },
}
// Actions
export const actions = {
    // eslint-disable-next-line no-shadow
    async getCompanyArr({state, commit}) {
        const url = '/api/v1/search';

        const params = {
            page: state.pagination.current_page,
            perPage: state.pagination.per_page,
            sort: state.sort.desc + state.sort.by,
            company_page_published: state.ignore_published
        }
        if (state.filter.length) {
            state.filter.forEach(item => {
                params[item.key] = item.value;
            })
        }
        if (state.alphabetSelectLetter !== '' && state.search === ''){
            params.first_letter = 1;
            params.business_name = state.alphabetSelectLetter;
        }
        if (state.search !== ''){
            params.strict_name_search = 1;
            params.business_name = state.search;
        }

        commit(types.SET_FETCH_DATA, true)
        try {
            const {data} = await this.$axios.get(url, {params});
            const pagination = {...data};
            delete (pagination.data);
            const businesses = data.data.map(b => changeMappingCompany(b));

            commit(types.FETCH_BUSINESS_SUCCESS, businesses)
            commit(types.SET_MAIN_FILTER_PAGINATION, pagination)
        } catch (e) {
            console.log(e)
            commit(types.FETCH_BUSINESS_FAILURE)
        }
        commit(types.SET_FETCH_DATA, false)
    },
    async getAlphabetFilter({state, commit}) {
        const url = '/api/v2/business/alphabet-counter';
        const currentLetter = state.alphabetSelectLetter ?? '';

        try {
            let returnArr = [];
            const {data} = await this.$axios.get(url);

            returnArr = data;
            if (data.status === 'success')
                // eslint-disable-next-line no-return-assign
                returnArr = data.result.map(item => {
                    item.active = currentLetter.toLowerCase() === item.letter.toLowerCase()
                    return item
                });
            returnArr.unshift({
                letter: 'All',
                count: 1,
                active: currentLetter === ''
            })
            commit(types.SET_FILTER_ALPHABET_DATA, returnArr)
        } catch (e) {
            console.log(e)
        }
    },
    async fetchCompany ({ state, commit }, route = {}) {
      try {
        const {data} = await this.$axios.get(`/api/v1/business/${route.params}/data/latest`);
        const arrData = data.data[0];
        const franchise = {
          id: arrData.franchise_id,
          isVerified: arrData.certified_by_fa.attribute_value_string === '1',
          isPublish: arrData.company_page_published.attribute_value_string === 1,
          business_name: arrData.business_name.attribute_value_string,
          company_page_published: arrData.company_page_published.attribute_value_string,
          certified_by_fa: arrData.certified_by_fa.attribute_value_string,
          company_twitter_username:
            arrData.company_twitter_username.attribute_value_string === ''
              ? '@Franchise1_2_3'
              : arrData.company_twitter_username.attribute_value_string,
          company_stage: arrData.company_stage.attribute_value_string,
          company_description: arrData.company_description.attribute_value_string,
          industry: arrData.industry.attribute_value_string,
          number_of_locations: arrData.number_of_locations.attribute_value_string,
          location: arrData.location.attribute_value_string ?? 'N/A',
          websiteUrl: arrData.public_website_business_permalink.attribute_value_string ?? '',
          membership: arrData.membership.attribute_value_string,
          established: arrData.established.attribute_value_string,
          franchising_since: arrData.franchising_since.attribute_value_string,
          investment_min: arrData.investment.attribute_value_numeric_low,
          investment_max: arrData.investment.attribute_value_numeric_high,
          franchise_fee_min: arrData.franchise_fee.attribute_value_numeric_low,
          franchise_fee_max: arrData.franchise_fee.attribute_value_numeric_high,
          gross_profit: (arrData.gross_profit.attribute_value_string
            && Number(arrData.gross_profit.attribute_value_string) > 0)
            ? arrData.gross_profit.attribute_value_string
            : '',
          average_revenue: (arrData.average_revenue.attribute_value_string
            && Number(arrData.average_revenue.attribute_value_string) > 0)
            ? arrData.average_revenue.attribute_value_string
            : '',
          main_royalty_fee: arrData.main_royalty_fee.attribute_value_string,
          main_royalty_fee_percent: arrData.main_royalty_fee_percent.attribute_value_string,
          slug_industry: arrData.slug_industry.attribute_value_string,
          seo_meta_title: arrData.seo_meta_title.attribute_value_string ?? '',
          seo_meta_keywords: arrData.seo_meta_keywords.attribute_value_string ?? '',
          seo_meta_description: arrData.seo_meta_description.attribute_value_string ?? '',
          short_descr: arrData.company_short_description.attribute_value_string ?? arrData.short_descr.attribute_value_string ?? '',
          video: arrData.video.attribute_value_string ?? '',
          presentation: arrData.files.presentation && arrData.files.presentation.url ? arrData.files.presentation.url : '',
          slug: arrData.slug,
          logo: arrData.logo.medium,
          founders: arrData.founders,
          ratings: arrData.external_ratings,
          like: arrData.like,
          in_comparison: arrData.in_comparison,
          flow_status: arrData.flow_status,
        }
        commit(types.FETCH_COMPANY_SUCCESS, franchise);
      } catch (e) {
        console.log(e)
        commit(types.FETCH_ANSWERS_FAILURE)
      }

    }
}
