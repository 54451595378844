export default async ({store, $axios}) => {
  const token = store.getters['auth/token']
  const remember = store.getters['auth/remember']

  store.dispatch('auth/setPagePrivateStatus', false)
  if (!token)
    return

  $axios.setToken(token ?? false, 'Bearer')
  await store.dispatch('auth/fetchUser')

  const check = store.getters['auth/check']
  if (check)
    return

  if (!remember)
    return

  await store.dispatch('auth/refreshToken')
  $axios.setToken(store.getters['auth/token'] ?? false, 'Bearer')
  await store.dispatch('auth/fetchUser')
}
